const ERC20ABI = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_from",
        type: "address",
      },
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
      {
        name: "_spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
];
const DAIABI = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "chainId_",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "src",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "guy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: true,
    inputs: [
      {
        indexed: true,
        internalType: "bytes4",
        name: "sig",
        type: "bytes4",
      },
      {
        indexed: true,
        internalType: "address",
        name: "usr",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "arg1",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "arg2",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "LogNote",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "src",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "dst",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    constant: true,
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "PERMIT_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "usr",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "usr",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "burn",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "guy",
        type: "address",
      },
    ],
    name: "deny",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "usr",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "mint",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "src",
        type: "address",
      },
      {
        internalType: "address",
        name: "dst",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "move",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "nonces",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "holder",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expiry",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "allowed",
        type: "bool",
      },
      {
        internalType: "uint8",
        name: "v",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "r",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "s",
        type: "bytes32",
      },
    ],
    name: "permit",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "usr",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "pull",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "usr",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "push",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "guy",
        type: "address",
      },
    ],
    name: "rely",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "dst",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "src",
        type: "address",
      },
      {
        internalType: "address",
        name: "dst",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "wad",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "version",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "wards",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

const LOOGIEABI = [
  {
    inputs:[

    ],
    stateMutability:"nonpayable",
    type:"constructor"
  },
  {
    anonymous:false,
    inputs:[
      {
        indexed:true,
        internalType:"address",
        name:"owner",
        type:"address"
      },
      {
        indexed:true,
        internalType:"address",
        name:"approved",
        type:"address"
      },
      {
        indexed:true,
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"Approval",
    type:"event"
  },
  {
    anonymous:false,
    inputs:[
      {
        indexed:true,
        internalType:"address",
        name:"owner",
        type:"address"
      },
      {
        indexed:true,
        internalType:"address",
        name:"operator",
        type:"address"
      },
      {
        indexed:false,
        internalType:"bool",
        name:"approved",
        type:"bool"
      }
    ],
    name:"ApprovalForAll",
    type:"event"
  },
  {
    anonymous:false,
    inputs:[
      {
        indexed:true,
        internalType:"address",
        name:"previousOwner",
        type:"address"
      },
      {
        indexed:true,
        internalType:"address",
        name:"newOwner",
        type:"address"
      }
    ],
    name:"OwnershipTransferred",
    type:"event"
  },
  {
    anonymous:false,
    inputs:[
      {
        indexed:true,
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        indexed:true,
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        indexed:true,
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"Transfer",
    type:"event"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"approve",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"owner",
        type:"address"
      }
    ],
    name:"balanceOf",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"baseURI",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    name:"chubbiness",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    name:"color",
    outputs:[
      {
        internalType:"bytes3",
        name:"",
        type:"bytes3"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"curve",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"getApproved",
    outputs:[
      {
        internalType:"address",
        name:"",
        type:"address"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"owner",
        type:"address"
      },
      {
        internalType:"address",
        name:"operator",
        type:"address"
      }
    ],
    name:"isApprovedForAll",
    outputs:[
      {
        internalType:"bool",
        name:"",
        type:"bool"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"limit",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"mintItem",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"payable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    name:"mouthLength",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"name",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"owner",
    outputs:[
      {
        internalType:"address",
        name:"",
        type:"address"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"ownerOf",
    outputs:[
      {
        internalType:"address",
        name:"",
        type:"address"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"price",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"recipient",
    outputs:[
      {
        internalType:"address payable",
        name:"",
        type:"address"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"id",
        type:"uint256"
      }
    ],
    name:"renderTokenById",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"renounceOwnership",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"safeTransferFrom",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      },
      {
        internalType:"bytes",
        name:"_data",
        type:"bytes"
      }
    ],
    name:"safeTransferFrom",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"operator",
        type:"address"
      },
      {
        internalType:"bool",
        name:"approved",
        type:"bool"
      }
    ],
    name:"setApprovalForAll",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"bytes4",
        name:"interfaceId",
        type:"bytes4"
      }
    ],
    name:"supportsInterface",
    outputs:[
      {
        internalType:"bool",
        name:"",
        type:"bool"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"symbol",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"index",
        type:"uint256"
      }
    ],
    name:"tokenByIndex",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"owner",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"index",
        type:"uint256"
      }
    ],
    name:"tokenOfOwnerByIndex",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"id",
        type:"uint256"
      }
    ],
    name:"tokenURI",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"totalSupply",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"transferFrom",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"newOwner",
        type:"address"
      }
    ],
    name:"transferOwnership",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  }
];

const FANCYLOOGIEABI = [
  {
    inputs:[
      {
        internalType:"address",
        name:"_loogies",
        type:"address"
      }
    ],
    stateMutability:"nonpayable",
    type:"constructor"
  },
  {
    anonymous:false,
    inputs:[
      {
        indexed:true,
        internalType:"address",
        name:"owner",
        type:"address"
      },
      {
        indexed:true,
        internalType:"address",
        name:"approved",
        type:"address"
      },
      {
        indexed:true,
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"Approval",
    type:"event"
  },
  {
    anonymous:false,
    inputs:[
      {
        indexed:true,
        internalType:"address",
        name:"owner",
        type:"address"
      },
      {
        indexed:true,
        internalType:"address",
        name:"operator",
        type:"address"
      },
      {
        indexed:false,
        internalType:"bool",
        name:"approved",
        type:"bool"
      }
    ],
    name:"ApprovalForAll",
    type:"event"
  },
  {
    anonymous:false,
    inputs:[
      {
        indexed:true,
        internalType:"address",
        name:"previousOwner",
        type:"address"
      },
      {
        indexed:true,
        internalType:"address",
        name:"newOwner",
        type:"address"
      }
    ],
    name:"OwnershipTransferred",
    type:"event"
  },
  {
    anonymous:false,
    inputs:[
      {
        indexed:true,
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        indexed:true,
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        indexed:true,
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"Transfer",
    type:"event"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"nft",
        type:"address"
      }
    ],
    name:"addNft",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"approve",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"owner",
        type:"address"
      }
    ],
    name:"balanceOf",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"id",
        type:"uint256"
      }
    ],
    name:"downgradeLoogie",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"getApproved",
    outputs:[
      {
        internalType:"address",
        name:"",
        type:"address"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"getContractsAddress",
    outputs:[
      {
        internalType:"address[]",
        name:"",
        type:"address[]"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"nft",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"id",
        type:"uint256"
      }
    ],
    name:"hasNft",
    outputs:[
      {
        internalType:"bool",
        name:"",
        type:"bool"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"owner",
        type:"address"
      },
      {
        internalType:"address",
        name:"operator",
        type:"address"
      }
    ],
    name:"isApprovedForAll",
    outputs:[
      {
        internalType:"bool",
        name:"",
        type:"bool"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"loogieId",
        type:"uint256"
      }
    ],
    name:"mintItem",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"name",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    name:"nftContracts",
    outputs:[
      {
        internalType:"contract NFTContract",
        name:"",
        type:"address"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"nftContractsCount",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"nft",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"id",
        type:"uint256"
      }
    ],
    name:"nftId",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"operator",
        type:"address"
      },
      {
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      },
      {
        internalType:"bytes",
        name:"fancyIdData",
        type:"bytes"
      }
    ],
    name:"onERC721Received",
    outputs:[
      {
        internalType:"bytes4",
        name:"",
        type:"bytes4"
      }
    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"owner",
    outputs:[
      {
        internalType:"address",
        name:"",
        type:"address"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"ownerOf",
    outputs:[
      {
        internalType:"address",
        name:"",
        type:"address"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"nft",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"id",
        type:"uint256"
      }
    ],
    name:"removeNftFromLoogie",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"id",
        type:"uint256"
      }
    ],
    name:"renderTokenById",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"renounceOwnership",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"safeTransferFrom",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      },
      {
        internalType:"bytes",
        name:"_data",
        type:"bytes"
      }
    ],
    name:"safeTransferFrom",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"operator",
        type:"address"
      },
      {
        internalType:"bool",
        name:"approved",
        type:"bool"
      }
    ],
    name:"setApprovalForAll",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"bytes4",
        name:"interfaceId",
        type:"bytes4"
      }
    ],
    name:"supportsInterface",
    outputs:[
      {
        internalType:"bool",
        name:"",
        type:"bool"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"symbol",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"index",
        type:"uint256"
      }
    ],
    name:"tokenByIndex",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"owner",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"index",
        type:"uint256"
      }
    ],
    name:"tokenOfOwnerByIndex",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"uint256",
        name:"id",
        type:"uint256"
      }
    ],
    name:"tokenURI",
    outputs:[
      {
        internalType:"string",
        name:"",
        type:"string"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[

    ],
    name:"totalSupply",
    outputs:[
      {
        internalType:"uint256",
        name:"",
        type:"uint256"
      }
    ],
    stateMutability:"view",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"from",
        type:"address"
      },
      {
        internalType:"address",
        name:"to",
        type:"address"
      },
      {
        internalType:"uint256",
        name:"tokenId",
        type:"uint256"
      }
    ],
    name:"transferFrom",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  },
  {
    inputs:[
      {
        internalType:"address",
        name:"newOwner",
        type:"address"
      }
    ],
    name:"transferOwnership",
    outputs:[

    ],
    stateMutability:"nonpayable",
    type:"function"
  }
];

const LOOGIETANKABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address"
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "Approval",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool"
      }
    ],
    name: "ApprovalForAll",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "Transfer",
    type: "event"
  },
  {
    inputs: [],
    name: "_tokenIds",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    name: "componentByTankId",
    outputs: [
      {
        internalType: "uint256",
        name: "blockAdded",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "id",
        type: "uint256"
      },
      {
        internalType: "address",
        name: "addr",
        type: "address"
      },
      {
        internalType: "uint8",
        name: "x",
        type: "uint8"
      },
      {
        internalType: "uint8",
        name: "y",
        type: "uint8"
      },
      {
        internalType: "uint8",
        name: "scale",
        type: "uint8"
      },
      {
        internalType: "int8",
        name: "dx",
        type: "int8"
      },
      {
        internalType: "int8",
        name: "dy",
        type: "int8"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "address",
        name: "operator",
        type: "address"
      }
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "mintItem",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "payable",
    type: "function"
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "price",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256"
      }
    ],
    name: "renderTokenById",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_id",
        type: "uint256"
      }
    ],
    name: "returnAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes"
      }
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "senEthToOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address"
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool"
      }
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4"
      }
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256"
      }
    ],
    name: "tokenByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256"
      }
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256"
      }
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address"
      },
      {
        internalType: "address",
        name: "to",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      }
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "nftAddr",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "tankId",
        type: "uint256"
      },
      {
        internalType: "uint8",
        name: "scale",
        type: "uint8"
      }
    ],
    name: "transferNFT",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
]

// Mainnet DAI, Optimism and Arbitrium Rollup Contracts with local addresses
module.exports = {
  // ethereum mainnet
  1: {
    contracts: {
      DAI: {
        address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        abi: DAIABI,
      },
      UNI: {
        address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
        abi: ERC20ABI,
      },
    },
  },
  // optimism mainnet
  10: {
    contracts: {
      Loogies: {
        address: "0x006eB613cc586198003a119485594ECbbDf41230",
        abi: LOOGIEABI
      },
      FancyLoogies: {
        address: "0x43693eeC62666D621ba33095090BE60d4aF6D6FA",
        abi: FANCYLOOGIEABI
      },
      LoogieTank: {
        address: "0x37E2c6400214ae49339f2BEfAB1Abf3Dd39b2b74",
        abi: LOOGIETANKABI
      }
    }
  },
  // optimism kovan
  69: {
    contracts: {
      Loogies: {
        address: "0xF16b5c4232a2D0C9b0e01257a7F79bCcca958d3F",
        // address: "0xE203cDC6011879CDe80c6a1DcF322489e4786eB3",
        abi: LOOGIEABI
      },
      FancyLoogies: {
        address: "0x43693eeC62666D621ba33095090BE60d4aF6D6FA",
        abi: FANCYLOOGIEABI
      }
    }
  },
  // hardhat local chain
  31337: {
    contracts: {
      Loogies: {
        // address: "0xF16b5c4232a2D0C9b0e01257a7F79bCcca958d3F",
        address: "0xE203cDC6011879CDe80c6a1DcF322489e4786eB3",
        abi: LOOGIEABI
      },
    }
  }
};
